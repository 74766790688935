<template>
  <PltuForm mode="Ubah" module="PLTU"> </PltuForm>
</template>

<script>
import PltuForm from './form';

const PltuUpdate = {
  name: 'PltuUpdate',
  components: { PltuForm },
};

export default PltuUpdate;
</script>
